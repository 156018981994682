"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("@services/hyperlinks-helper");
(function (module) {
    module.factory('prTextEditorMenu', [
        'pr.globalOptionsService',
        'prTextFormatsHelper',
        'prQuillFormatter',
        '$rootScope',
        'prPipedTextMenuService',
        'prHyperlinksHelper',
        '$q',
        function (globalOptionsService, textFormatsHelper, QuillFormatter, $rootScope, PipedTextMenu, hyperlinksHelper, $q) {
            var menu = [];
            function getTextEditorMenu() {
                return menu;
            }
            var DIVIDER_AFTER = [
                'header',
                'font',
                'size',
                'underline',
                'color',
                'clean'
            ];
            // https://statwingb1.b1-prv.qualtrics.com/WRQualtricsShared/JavaScript/ckeditor4.4.6/plugins/qsmarttext/icons/qsmarttext.png
            function showTextEditorMenu(quillEditor, editorLocator, widgetId, includePipedText, includeHyperlinks) {
                hideTextEditorMenu(); // clear out menu array
                var quillFormatter = new QuillFormatter(quillEditor);
                var options = textFormatsHelper.getAllFormatOptions();
                _.forEach(options, function (o) {
                    o.action = quillFormatter[o.actionFn].bind(quillFormatter);
                    menu.push(o);
                    if (_.contains(DIVIDER_AFTER, o.name)
                        || o.name === 'list' && o.value === 'bullet'
                        || o.name === 'align' && o.value === 'right') {
                        menu.push({ divider: true });
                    }
                });
                var getPipedTextMenuPromise = includePipedText
                    ? new PipedTextMenu(quillEditor, editorLocator, widgetId).getPipedTextMenu()
                    : $q.when();
                return getPipedTextMenuPromise.then(function (pipedTextMenu) {
                    if (pipedTextMenu) {
                        menu.push(pipedTextMenu);
                    }
                    if (includeHyperlinks) {
                        menu.push(new hyperlinksHelper(quillEditor, widgetId).getHyperlinksMenuItem());
                    }
                    if (!includePipedText && !includeHyperlinks) {
                        menu.pop();
                    }
                    quillEditor.on('text-change', function () {
                        updateTextEditorMenu(quillEditor);
                        if (quillEditor.onChange) {
                            quillEditor.onChange();
                        }
                    });
                    quillEditor.on('selection-change', function () {
                        if (quillEditor.hasFocus()) {
                            updateTextEditorMenu(quillEditor);
                        }
                    });
                    if (!$rootScope.$$phase) {
                        $rootScope.$digest();
                    }
                    quillEditor.setSelection(quillEditor.getLength() - 1);
                });
            }
            function hideTextEditorMenu(quillEditor) {
                menu.length = 0; // clear out menu array
                if (quillEditor) {
                    quillEditor.off('text-change');
                    quillEditor.off('selection-change');
                }
                if (!$rootScope.$$phase) {
                    $rootScope.$digest();
                }
            }
            var HEADER_TYPES = ['H1', 'H2', 'H3', 'H4', 'H5'];
            function processFormat(format) {
                format = format || {};
                format = _.cloneDeep(format);
                var header = format.header;
                var cl = format['class'];
                var styles, textStyles;
                if (header) {
                    textStyles = globalOptionsService.getTextFormats();
                    styles = textStyles[HEADER_TYPES[header - 1]] || {};
                    styles.type = HEADER_TYPES[header - 1];
                }
                else if (cl) {
                    textStyles = globalOptionsService.getTextFormats();
                    styles = textStyles[cl] || {};
                    styles.type = cl;
                }
                else {
                    // get global option styles
                    styles = globalOptionsService.getBodyTextFormats();
                    styles.type = 'body';
                }
                format.type = styles.type;
                format.font = format.font || styles.FONT;
                format.size = parseInt(format.size || styles.SIZE, 10) || undefined;
                format.color = format.color || styles.COLOR;
                format.align = format.align || false;
                return format;
            }
            function updateTextEditorMenu(quillEditor) {
                quillEditor.focus();
                var range = quillEditor.getSelection();
                if (!range) {
                    return;
                }
                var formats = quillEditor.getFormat(range), processedFormats = processFormat(formats);
                for (var i = 0, len = menu.length; i < len; ++i) {
                    var option = menu[i], format = option.name;
                    if (option.type === 'menu') {
                        if (_.isArray(formats[format])) {
                            option.selectedFormat = undefined;
                            continue;
                        }
                        switch (format) {
                            case 'header':
                                option.selectedFormat = processedFormats.type;
                                break;
                            case 'font':
                                option.selectedFormat = processedFormats.font;
                                break;
                            case 'size':
                                option.selectedFormat = processedFormats.size;
                                break;
                        }
                    }
                    else if (option.type === 'button') {
                        if (format === 'align') {
                            // left is default if no current format
                            option.selected = formats[format] ? formats[format] === option.value : option.value === 'left';
                        }
                        else if (format === 'list') {
                            option.selected = formats[format] === option.value;
                        }
                        else {
                            option.selected = formats[format] || false;
                        }
                    }
                    else if (option.type === 'color') {
                        option.selectedColor = processedFormats.color;
                    }
                }
                if (!$rootScope.$$phase) {
                    $rootScope.$digest();
                }
            }
            return {
                getTextEditorMenu: getTextEditorMenu,
                showTextEditorMenu: showTextEditorMenu,
                updateTextEditorMenu: updateTextEditorMenu,
                hideTextEditorMenu: hideTextEditorMenu
            };
        }
    ]);
})(angular.module('qualtrics.pr'));
