"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var exports_1 = require("@shared/constants/exports");
(function (module) {
    'use strict';
    module.factory('pr.API_ROUTES', [
        'pr.reportTypeService',
        'prOverrideTrigger',
        'pr.OVERRIDE_EVENTS',
        '$location',
        function (reportTypeService, triggerOverrides, OVERRIDE_EVENTS, $location) {
            var routes = {
                ADMIN_SERVICE: 'api/adminService',
                BULK_FIELD: 'api/bulkField/',
                CONTAINERS: 'ripsaw/containers',
                CONVERSION: 'api/conversion',
                DISPLAY_LOGIC: 'api/displayLogic',
                DATA: 'data/',
                DATA_FILTERING_SERVICE: 'api/dataFilteringService',
                DYNAMIC_TRANSLATIONS: 'api/dynamic-translations',
                EXPORT: 'export/',
                FEATURE_FLIPPER: 'api/feature-flipper',
                FIELDSETS: 'fieldsets/',
                FOLDER_SELECTOR: '/myprojects/ui/selector',
                FSCS: 'fscs/',
                GLOBAL_REPORT_OPTIONS: 'globalReportOptions/options',
                GRAPHIC_LIBRARIES: 'graphicLibraries/',
                IMPORT: 'api/import/',
                ISOLATION_SERVICE: 'api/isolationService',
                IS_IN_ANALYTICS_ENGINE: 'fieldsets/isInAnalyticsEngine/',
                LOGGER: 'api/logger',
                MONOLITH_BASE_URL: '/Q/ReportingSection/Ajax/',
                ORG_HIERARCHY_SERVICE: 'api/orgHierarchyService',
                PARTIAL_DATA: 'partialData/',
                PERSON_SERVICE: 'api/personService/',
                PUBLIC: 'public/',
                REPORTS: 'api/reports',
                SAVED_FILTERS_PROXY: 'ripsaw/v2/fieldsets',
                SCHEDULE_EMAIL: 'api/schedule-email',
                SOURCES: 'sources/',
                SURVEYS: 'surveys/',
                THREE_SIXTY_RATER_DISPLAY_LOGIC: 'api/threeSixtyRaterDisplayLogic',
                THREE_SIXTY_RELATIONSHIPS: 'api/threeSixtyRelationships',
                THREE_SIXTY_SERVICE: 'api/threeSixtyService/',
                VIEW: 'view/'
            };
            routes = triggerOverrides({
                event: OVERRIDE_EVENTS.ROUTES,
                data: routes,
                sync: true
            });
            return _.mapValues(routes, function (path) {
                if (path[0] === '/') {
                    return path;
                }
                var isExport = $location.path().startsWith(exports_1.EXPORTS_URL_PREFIX);
                var pathPrefix = isExport ? exports_1.EXPORTS_URL_PREFIX : '/reports';
                return pathPrefix + "/" + reportTypeService.getReportType() + "/" + path;
            });
        }
    ]);
}(angular.module('qualtrics.pr')));
