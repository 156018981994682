"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./floating-container.less");
require("../floating-container-helper");
(function (module) {
    'use strict';
    /*
     * Positions a widget inside the floating container and will handle moving between the widgets
     */
    var CONTAINER_SEL = 'pr-floating-container', ZOOM_SEL = '.pr-zoom-content', WIDGET_SEL = 'pr-widget', MOVE_CHUNK_ERROR = 8; // Upped from 5 after finding isues with chunks that start with large elements like headers
    // TODO: think of a better name for this since it only deals with the text widget
    module.factory('prFloatingContainer', [
        '$window',
        '$filter',
        'prHiddenRenderer',
        'prWidgetChunkerFactory',
        'pr.floatingContainerHelper',
        'pr.widgetUtils',
        function ($window, $filter, hiddenRenderer, chunker, floatingContainerHelper, widgetUtils) {
            var translate = $filter('translate');
            var floater, observer, scrollTop, maxHeight, activeWidget, activeChunkIndex;
            // this gets called for the first time in show because we are relying on a selector
            // that may not exsit in the dom when this factory is injected
            function initFloater() {
                floater = $(CONTAINER_SEL);
                observer = new MutationObserver(updateHeight);
                reset();
                floater.on('scroll', moveToChunk);
            }
            function reset() {
                showChunkContent();
                scrollTop = 0;
                maxHeight = 0;
                activeWidget = null;
                activeChunkIndex = null;
            }
            function show(widget, chunkIndex, event, $customBody) {
                if (!floater) {
                    initFloater();
                }
                floater.show();
                var $parent = $customBody === null || $customBody === void 0 ? void 0 : $customBody.parent();
                if (activeWidget !== widget) {
                    floatingContainerHelper.moveBodyToFloatingContainer(widget, floater, $customBody);
                }
                showChunkContent();
                activeWidget = widget;
                activeChunkIndex = chunkIndex;
                hideChunkContent();
                position($parent);
                observe();
                if (event) {
                    setCursorPosition(event);
                }
                else if (isDefaultText()) {
                    setTimeout(function () {
                        selectAllText();
                    }, 0);
                }
            }
            function hide() {
                if (activeWidget) {
                    floater.hide();
                    floatingContainerHelper.removeBodyFromFloatingContainer();
                    stopObserving();
                    reset();
                }
            }
            function position($customElement) {
                var chunk = $customElement !== null && $customElement !== void 0 ? $customElement : getChunk(), chunkOffset = chunk.offset(), parentOffset = floater.parent().offset(), zoom = $(ZOOM_SEL).getZoom();
                hideChunkContent();
                maxHeight = chunk.height();
                floater.css({
                    top: (chunkOffset.top - parentOffset.top) / zoom,
                    left: (chunkOffset.left - parentOffset.left) / zoom,
                    maxHeight: maxHeight / zoom
                });
                // updae our scrolltop
                updateScrollTop($customElement);
            }
            function observe() {
                stopObserving();
                if (isActiveChunkableWidget()) {
                    var chunk = getChunk(), widget = chunk.closest(WIDGET_SEL);
                    // watch to see if the widget's height updates, if so, then we want to update our height as well
                    if (widget.length) {
                        observer.observe(widget[0], { attributes: true, attributeFilter: ['style'] });
                    }
                }
            }
            function stopObserving() {
                return observer && observer.disconnect();
            }
            function moveToChunk(evt) {
                evt.stopImmediatePropagation();
                var newTop = floater.scrollTop();
                // give us an error of 5px
                if (Math.abs(newTop - scrollTop) <= MOVE_CHUNK_ERROR) {
                    return;
                }
                var chunkIndex = activeChunkIndex + (newTop > scrollTop ? 1 : -1);
                // make sure it is a valid chunk before trying to move to it
                // when the text item is empty, or when there is room to expand this often happens
                if (chunkIndex < activeWidget.chunks.length) {
                    show(activeWidget, chunkIndex);
                }
                else {
                    // if we scrolled, but we are expanding, then we should reset the scroll
                    updateScrollTop();
                }
            }
            function updateScrollTop($customElement) {
                scrollTop = $customElement ? $customElement[0].clientHeight : getChunkTop();
                floater.scrollTop(scrollTop);
            }
            function isActiveChunkableWidget() {
                return activeWidget && !widgetUtils.isFixedHeightWidget(activeWidget._id);
            }
            function showChunkContent() {
                if (isActiveChunkableWidget()) {
                    getChunk().removeClass('hidden-content');
                }
            }
            function hideChunkContent() {
                if (isActiveChunkableWidget()) {
                    getChunk().addClass('hidden-content');
                }
            }
            function updateHeight() {
                var zoom = $(ZOOM_SEL).getZoom(), height = getChunk().height();
                floater.css('maxHeight', height / zoom);
                updateScrollTop();
            }
            function getChunk() {
                if (!isActiveChunkableWidget()) {
                    return;
                }
                return $(chunker.getChunk(activeWidget, activeChunkIndex));
            }
            function getChunkTop() {
                if (!isActiveChunkableWidget()) {
                    return;
                }
                return chunker.getChunkTop(activeWidget, activeChunkIndex);
            }
            function getActiveChunkIndex() {
                return activeChunkIndex;
            }
            // Puts the focus at the end of the text element
            function giveFocusEnd() {
                var el = $(CONTAINER_SEL + ' .ql-editor')[0];
                if (el) {
                    el.focus();
                    if (typeof window.getSelection !== 'undefined'
                        && typeof document.createRange !== 'undefined') {
                        var range = document.createRange();
                        range.selectNodeContents(el);
                        range.collapse(false);
                        var sel = window.getSelection();
                        sel.removeAllRanges();
                        sel.addRange(range);
                    }
                    else if (typeof document.body.createTextRange !== 'undefined') {
                        var textRange = document.body.createTextRange();
                        textRange.moveToElementText(el);
                        textRange.collapse(false);
                        textRange.select();
                    }
                }
            }
            // set's the cursor position exactly where the mouse is
            function setCursorPosition(event) {
                if (!window.getSelection) {
                    return;
                }
                // We are making HUGE assumptions that are 90% of the time correct:
                // 1. This is coming from a mousedown event so we need to use setTimeout
                // 		to wait for the browser to finish it's default action of setting the selection
                // 2. The text in the overlay are positioned exactly so the mouse is over the clone
                // 3. It will fail on the second page if we had to split a text item right in the middle of a paragraph
                setTimeout(function () {
                    try {
                        // select all the text in the event target, then find where it ends
                        if (isDefaultText()) {
                            selectAllText();
                            return;
                        }
                        var sel = window.getSelection(), range = sel.getRangeAt(0), start = range.startOffset, end = range.endOffset, el = document.elementFromPoint(event.clientX, event.clientY), elText = el && el.childNodes[0];
                        if (!elText) {
                            return;
                        }
                        // we assume that the div is positioned exactly over the other text area,
                        // and we didn't split in the middle of a tag. (This fails if we did)
                        el.focus();
                        range = document.createRange();
                        range.setStart(elText, start);
                        range.setEnd(elText, end);
                        sel = window.getSelection();
                        sel.removeAllRanges();
                        sel.addRange(range);
                    }
                    catch (e) {
                        // there was an error
                        // TODO: We potentially could use the end position of the current
                        // selection if the tag is split and we are on the second page
                    }
                }, 0);
            }
            function isDefaultText() {
                try {
                    var text = _.trim(floater.text());
                    return text === translate('PR.TEXT_WIDGET_DEFAULT_TEXT');
                }
                catch (e) {
                    return false;
                }
            }
            // Note: this was written to select default text with the assumption it will
            // be a single line and not be chunked
            function selectAllText() {
                try {
                    var editor = floater.find('.ql-editor')[0].childNodes[0];
                    editor.focus();
                    var range = document.createRange(0);
                    range.selectNodeContents(editor);
                    var sel = window.getSelection();
                    sel.removeAllRanges();
                    sel.addRange(range);
                }
                catch (e) {
                    return false;
                }
            }
            function focus(event) {
                var el = document.elementFromPoint(event.pageX, event.pageY);
                $(el).trigger(createMouseDownEvent(event));
            }
            function createMouseDownEvent(event) {
                var props = _.reduce(['pageX', 'pageY', 'clientX', 'clientY'], function (ret, prop) {
                    ret[prop] = event[prop];
                    return ret;
                }, {
                    which: 1 // jquery.ui.mouse will not propogate the _mouseDown if the left mouse button wasn't clicked
                });
                return $.Event('mousedown', props);
            }
            return {
                show: show,
                hide: hide,
                focus: focus,
                getActiveChunkIndex: getActiveChunkIndex,
                giveFocusEnd: giveFocusEnd
            };
        }
    ]);
}(angular.module('qualtrics.pr')));
